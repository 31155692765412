#conteudo_nfce * {
  margin: 0;
  font-family: sans-serif;
  text-transform: uppercase;
  width: 100%;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
}

#conteudo_nfce {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  color: black !important;
}

#conteudo_nfce {
  padding: 2px;
  margin: 0;
}

#conteudo_nfce h6 {
  margin: 1.5px;
  font-size: 10px;
  font-weight: 600;
}

.itensText {
  text-align: start;
  font-size: 9px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 2px 0.5rem !important;
  margin: 0;
}

.descProd {
  overflow: hidden;
  text-overflow: clip;
  width: 78px;
  max-height: 22px;
  font-weight: 600;
}

.itensBorder {
  border-width: 0 0 1px 0;
  border-color: black;
  border-style: solid;
}

.headerText {
  text-align: start;
  font-size: 9px;
  font-weight: 700;
  padding: 0;
  margin: 0;
}

.tipoEmissao {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 7px 0 20px 0;
  font-size: 10px;
  font-weight: 700;
  text-align: center;
}

.listagemValores {
  display: flex;
  justify-content: space-between;
  text-align: left;
}

.listagemValores p {
  font-size: 10px !important;
  font-weight: 700;
}

.listaValor {
  text-align: right;
}

.infoDocumento {
  text-align: center;
}

#danfe_cancelada {
  opacity: 0.4;
  position: absolute;
  top: 25%;
  left: 10%;
  font-size: 12vw;
  color: #bf3030;
}